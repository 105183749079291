import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Widgets from '@/views/Widgets.vue'

const Login = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "user" */ '@/views/Login.vue')
const ResetPwd = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "user" */ '@/views/ResetPwd.vue')
const Authtoken = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "user" */ '@/views/Authtoken.vue')
const UserSettings = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "user" */ '@/views/UserSettings.vue')
const About = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "user" */ '@/views/About.vue')

const SettingsUsers = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "settings" */ '@/views/SettingsUsers.vue')
const SettingsUser = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "settings" */ '@/views/SettingsUser.vue')
const SettingsSystem = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "settings" */ '@/views/SettingsSystem.vue')
const SettingsPriceGroups = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "settings" */ '@/views/SettingsPriceGroups')
const SettingsPriceGroup = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "settings" */ '@/views/SettingsPriceGroup')
const PaymentTerms = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "settings" */ '@/views/PaymentTerms.vue')
const PaymentTerm = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "settings" */ '@/views/PaymentTerm.vue')
const Forwarders = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "settings" */ '@/views/Forwarders.vue')
const Forwarder = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "settings" */ '@/views/Forwarder.vue')

const Sale = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sales" */ '@/views/Sale.vue')
const Sales = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sales" */ '@/views/Sales.vue')
const Client = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sales" */ '@/views/Client.vue')
const Clients = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sales" */ '@/views/Clients.vue')
const Action = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sales" */ '@/views/Action.vue')
const Actions = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sales" */ '@/views/Actions.vue')

const Supplier = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "purchases" */ '@/views/Supplier.vue')
const Suppliers = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "purchases" */ '@/views/Suppliers.vue')
const Purchase = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "purchases" */ '@/views/Purchase.vue')
const Purchases = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "purchases" */ '@/views/Purchases.vue')

const Product = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "products" */ '@/views/Product.vue')
const Products = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "products" */ '@/views/Products.vue')
const StockDetails = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "products" */ '@/views/StockDetails.vue')

const Reports = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "manager" */ '@/views/Reports.vue')
const Traceability = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "manager" */ '@/views/Traceability.vue')
const Invoices = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "manager" */ '@/views/Invoices.vue')

Vue.use(Router)

const router = new Router({
  routes: [
    // order of routes is important!!!
    {
      path: '/',
      component: Widgets
    },
    {
      path: '/about',
      component: About
    },
    {
      name: 'clientsub',
      path: '/clients/:id/:submenu/:subId',
      component: Client,
      props: (route) => ({
        submenu: route.params.submenu,
        subId: route.params.subId
      })
    },
    {
      name: 'client',
      path: '/clients/:id/:submenu',
      component: Client,
      props: (route) => ({
        submenu: route.params.submenu
      })
    },
    {
      path: '/clients/:id',
      redirect: '/clients/:id/details'
    },
    {
      name: 'clients',
      path: '/clients',
      component: Clients
    },
    {
      name: 'actions',
      path: '/actions',
      component: Actions
    },
    {
      name: 'action',
      path: '/actions/:id',
      component: Action
    },

    {
      name: 'sale',
      path: '/sales/:id',
      component: Sale
    },
    {
      name: 'sales',
      path: '/sales',
      component: Sales
    },

    {
      name: 'supplier',
      path: '/suppliers/:id',
      component: Supplier
    },
    {
      name: 'suppliers',
      path: '/suppliers',
      component: Suppliers
    },

    {
      name: 'product',
      path: '/products/:id',
      component: Product
    },
    {
      name: 'products',
      path: '/products',
      component: Products
    },

    {
      name: 'purchase',
      path: '/purchases/:id',
      component: Purchase
    },
    {
      name: 'purchases',
      path: '/purchases',
      component: Purchases
    },
    {
      path: '/more/reports',
      component: Reports
    },
    {
      path: '/more/traceability',
      component: Traceability
    },
    {
      path: '/more/invoices',
      redirect: '/more/invoices/unpaid'
    },
    {
      name: 'invoices',
      path: '/more/invoices/:submenu',
      component: Invoices,
      props: (route) => ({
        submenu: route.params.submenu
      })
    },

    {
      name: 'stockdetails',
      path: '/more/stockdetails',
      component: StockDetails
    },
    {
      path: '/settings',
      redirect: '/settings/users'
    },
    {
      name: 'users',
      path: '/settings/users',
      component: SettingsUsers
    },
    {
      name: 'user',
      path: '/settings/user/:id',
      component: SettingsUser
    },

    {
      name: 'paymentTerms',
      path: '/settings/paymentterms',
      component: PaymentTerms
    },
    {
      name: 'paymentTerm',
      path: '/settings/paymentterms/:id',
      component: PaymentTerm
    },

    {
      name: 'forwarders',
      path: '/settings/forwarders',
      component: Forwarders
    },
    {
      name: 'forwarder',
      path: '/settings/forwarders/:id',
      component: Forwarder
    },
    {
      name: 'settingsSystem',
      path: '/settings/system',
      component: SettingsSystem
    },
    {
      name: 'priceGroups',
      path: '/settings/pricegroups',
      component: SettingsPriceGroups
    },
    {
      name: 'priceGroup',
      path: '/settings/pricegroups/:id',
      component: SettingsPriceGroup
    },
    {
      name: 'login',
      path: '/login',
      component: Login
    },
    {
      name: 'pwdlost',
      path: '/pwdlost',
      component: ResetPwd
    },
    {
      name: 'authtoken',
      path: '/authtoken/:authtoken',
      component: Authtoken,
      props: (route) => ({
        authtoken: route.params.authtoken
      })
    },

    {
      path: '/usersettings',
      component: UserSettings
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (store.state.user) {
    console.log('There is a session, resume. (' + to.path + ')')
    next()
  } else {
    console.log(to.name, from.name)
    if (['login', 'signup', 'pwdlost', 'authtoken'].indexOf(to.name) === -1) {
      if (from.name === 'login') {
        // next(false)
      } else {
        next('login')
        // router.push({ path: 'login' })
        console.log('There is no session, redirect from ' + to.path + ' to /login. ')
      }
    } else {
      console.log('There is no session and you are already on the signup/login page')
      next()
    }
  }
})

export default router
