<template>
  <v-app
    dark
  >
    <v-main>
      <v-container>
        <v-row>
          <v-col my-2>
            <v-alert
              color="error"
              :icon="mdiAlert"
            >
              No se puede conectar con el servidor.
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mdiAlert } from '@mdi/js'
export default {
  data () {
    return {
      mdiAlert: mdiAlert
    }
  }
}
</script>
