<template>
  <v-container
    fluid
    style="overflow: auto"
  >
    <v-row
      dense
      align="stretch"
    >
      <v-col
        cols="12"
        sm="12"
        md="6"
        xl="4"
      >
        <v-card
          tile
          style="height: 100%;"
        >
          <v-card-title>
            <a
              href="/#/clients"
              style="text-decoration: none;"
            >
              Clientes
            </a>
          </v-card-title>
          <v-card-text
          >
            <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                        Clientes
                      </th>
                      <th
                        class="text-right"
                      >
                        Propios
                      </th>
                      <th
                        v-if="user.rights & availablePermissions['CAN_READALL']"
                        class="text-right"
                      >
                        Totales
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                    >
                      <td>Registros</td>
                      <td
                        class="text-right"
                      >
                        {{ (+widgets.widgetClients.ownrec || 0).toLocaleString() }}
                      </td>
                      <td
                        v-if="user.rights & availablePermissions['CAN_READALL']"
                        class="text-right"
                      >
                        {{ (+widgets.widgetClients.allrec || 0).toLocaleString() }}
                      </td>
                    </tr>
                    <tr
                    >
                      <td>Clientes (ult. 12 meses)</td>
                      <td
                        class="text-right"
                      >
                        {{ (+widgets.widgetClients.ownclients12m || 0).toLocaleString() }}
                      </td>
                      <td
                        v-if="user.rights & availablePermissions['CAN_READALL']"
                        class="text-right"
                      >
                        {{ (+widgets.widgetClients.allclients12m || 0).toLocaleString() }}
                      </td>
                    </tr>
                    <tr
                    >
                      <td>Facturación (ult. 12 meses) ({{defaultCurrency}})</td>
                      <td
                        class="text-right"
                      >
                        {{ Math.round(+widgets.widgetClients.ownsales12m || 0).toLocaleString() }}
                      </td>
                      <td
                        v-if="user.rights & availablePermissions['CAN_READALL']"
                        class="text-right"

                      >
                        {{ Math.round(+widgets.widgetClients.allsales12m || 0).toLocaleString() }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
        xl="4"
      >
        <v-card
          tile
          style="height: 100%;"

        >
          <v-card-title
          >
            <a
              href="/#/products"
              style="text-decoration: none;"
            >
              Productos
            </a>
          </v-card-title>
          <v-card-text
          >
            <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                        Productos
                      </th>
                      <th
                        class="text-right"
                      >
                        Sólo LS
                      </th>
                      <th
                        class="text-right"
                      >
                        Todos
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                    >
                      <td>Referencias</td>
                      <td
                        class="text-right"
                      >
                        {{ (+widgets.widgetProducts.lsref || 0).toLocaleString() }}
                      </td>
                      <td
                        class="text-right"
                      >
                        {{ (+widgets.widgetProducts.allref || 0).toLocaleString() }}
                      </td>
                    </tr>
                    <tr
                    >
                      <td>Referencias con stock</td>
                      <td
                        class="text-right"
                      >
                        {{ (+widgets.widgetProducts.lsrefstock || 0).toLocaleString() }}
                      </td>
                      <td
                        class="text-right"
                      >
                        {{ (+widgets.widgetProducts.allrefstock || 0).toLocaleString() }}
                      </td>
                    </tr>
                    <tr
                      v-if="user.rights & availablePermissions['CAN_PURCHASES_READ']"
                    >
                      <td>Valor stock ({{defaultCurrency}})</td>
                      <td
                        class="text-right"
                      >
                        {{ Math.round(+widgets.widgetProducts.lsrefstockvalue || 0).toLocaleString() }}
                      </td>
                      <td
                        class="text-right"
                      >
                        {{ Math.round(+widgets.widgetProducts.allrefstockvalue || 0).toLocaleString() }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
        xl="4"
      >
        <v-card
          tile
          style="height: 100%;"

        >
          <v-card-title
          >
            <a
              href="/#/actions"
              style="text-decoration: none;"
            >
              Acciones
            </a>
          </v-card-title>
          <v-card-text
          >
            <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>
                        Acciones
                      </th>
                      <th
                        class="text-right"
                      >
                        Propios
                      </th>
                      <th
                        v-if="user.rights & availablePermissions['CAN_READALL']"
                        class="text-right"
                      >
                        Totales
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                    >
                      <td>Registros</td>
                      <td
                        class="text-right"
                      >
                        {{ (+widgets.widgetActions.ownrec || 0).toLocaleString() }}
                      </td>
                      <td
                        v-if="user.rights & availablePermissions['CAN_READALL']"
                        class="text-right"
                      >
                        {{ (+widgets.widgetActions.allrec || 0).toLocaleString() }}
                      </td>
                    </tr>
                    <tr
                    >
                      <td>Registros (ult. 12 meses)</td>
                      <td
                        class="text-right"
                      >
                        {{ (+widgets.widgetActions.ownrec12m || 0).toLocaleString() }}
                      </td>
                      <td
                        v-if="user.rights & availablePermissions['CAN_READALL']"
                        class="text-right"
                      >
                        {{ (+widgets.widgetActions.allrec12m || 0).toLocaleString() }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '../api'

export default {
  data () {
    return {
      widgets: {
        widgetClients: {},
        widgetActions: {},
        widgetProducts: {}
      },
      user: this.$store.state.user,
      availablePermissions: this.$store.state.availablePermissions,
      defaultCurrency: this.$store.state.defaultCurrency
    }
  },
  mounted () {
    this.getItems()
  },
  methods: {
    getItems () {
      api.getItems('widgets')
        .then(data => {
          this.widgets = data
        })
        .catch(e => {
          this.$store.commit('alert',
            {
              show: true,
              text: 'Error al cargar datos!',
              type: 'error'
            }
          )
        })
    }

  }
}
</script>
