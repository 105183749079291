<template>
  <v-navigation-drawer
    v-if="user"
    fixed
    app
    v-model="navDrawer"
    clipped
    width="250"
  >
    <v-list
    >
      <v-list-item router-link :to="'/'">
        <v-list-item-action>
          <v-icon>{{ mdiHome }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Inicio
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="user.rights & availablePermissions['CAN_READ']"
        router-link :to="'/clients'"
      >
        <v-list-item-action>
          <v-icon>{{ mdiAccountMultipleOutline }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Clientes
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="user.rights & availablePermissions['CAN_READ']"
        router-link :to="'/actions'"
      >
        <v-list-item-action>
          <v-icon>{{ mdiSwapHorizontal }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Acciones
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="user.rights & availablePermissions['CAN_READ']"
        router-link :to="'/sales'"
      >
        <v-list-item-action>
          <v-icon>{{ mdiCash100 }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Ventas
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="user.rights & availablePermissions['CAN_PURCHASES_READ']"
        router-link :to="'/suppliers'"
      >
        <v-list-item-action>
          <v-icon>{{ mdiFactory }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Proveedores
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="user.rights & availablePermissions['CAN_PURCHASES_READ']"
        router-link :to="'/purchases'"
      >
        <v-list-item-action>
          <v-icon>{{ mdiTruck }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Compras
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="(user.rights & availablePermissions['CAN_READ']) || (user.rights & availablePermissions['CAN_PURCHASES_READ'])"
        router-link :to="'/products'"
      >
        <v-list-item-action>
          <v-icon>{{ mdiWarehouse }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Productos
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group
        v-if="(user.rights & availablePermissions['CAN_MANAGER']) || (user.rights & availablePermissions['CAN_INVOICE']) || (user.rights & availablePermissions['CAN_PURCHASES_READ'])"
        :prepend-icon="mdiDotsHorizontalCircle"
        no-action
      >
        <v-list-item slot="activator">
          <v-list-item-title>Más</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="user.rights & availablePermissions['CAN_MANAGER']"
          router-link
          :to="'/more/reports'"
        >
          <v-list-item-action>
            <v-icon>{{ mdiChartLine }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Reports
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="user.rights & availablePermissions['CAN_MANAGER']"
          router-link
          :to="'/more/traceability'"
        >
          <v-list-item-action>
            <v-icon>{{ mdiBarcodeScan }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Trazabilidad
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="user.rights & availablePermissions['CAN_INVOICE']"
          router-link
          :to="'/more/invoices'"
        >
          <v-list-item-action>
            <v-icon>{{ mdiFileDocumentMultiple }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Facturas
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="(user.rights & availablePermissions['CAN_PURCHASES_READ'])"
          router-link :to="'/more/stockdetails'"
        >
          <v-list-item-action>
            <v-icon>{{ mdiWarehouse }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Detalle Stock
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-group>

      <v-list-group
        v-if="user.rights & availablePermissions['CAN_ADMIN']"
        :prepend-icon="mdiCog"
        no-action
      >
        <v-list-item slot="activator">
          <v-list-item-title>Configuración</v-list-item-title>
        </v-list-item>

        <v-list-item
          router-link
          :to="'/settings/users'"
        >
          <v-list-item-action>
            <v-icon>{{ mdiAccountLock }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Usuarios
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          router-link
          :to="'/settings/system'"
        >
          <v-list-item-action>
            <v-icon>{{ mdiCogs }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Sistema
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          router-link
          :to="'/settings/pricegroups'"
        >
          <v-list-item-action>
            <v-icon>{{ mdiPercent }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Descuentos
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          router-link
          :to="'/settings/paymentterms'"
        >
          <v-list-item-action>
            <v-icon>{{ mdiCash100 }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Pagos
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          router-link
          :to="'/settings/forwarders'"
        >
          <v-list-item-action>
            <v-icon>{{ mdiTruck }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Transportes
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item router-link to="/about">
        <v-list-item-action>
          <v-icon>{{ mdiHelpCircle }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Acerca de
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {
  mdiDotsHorizontalCircle,
  mdiCog,
  mdiCogs,
  mdiChartLine,
  mdiBarcodeScan,
  mdiCube,
  mdiCart,
  mdiCash100,
  mdiAccountMultipleOutline,
  mdiAccountLock,
  mdiTruck,
  mdiFactory,
  mdiFormatListBulleted,
  mdiWarehouse,
  mdiClockOutline,
  mdiCompareHorizontal,
  mdiFileDocumentMultiple,
  mdiFilmstrip,
  mdiStrategy,
  mdiChartBubble,
  mdiAlert,
  mdiFingerprint,
  mdiSwapHorizontal,
  mdiPuzzle,
  mdiHome,
  mdiHelpCircle,
  mdiPercent
} from '@mdi/js'

export default {
  data () {
    return {
      user: this.$store.state.user,
      availablePermissions: this.$store.state.availablePermissions,
      mdiDotsHorizontalCircle: mdiDotsHorizontalCircle,
      mdiCog: mdiCog,
      mdiCogs: mdiCogs,
      mdiChartLine: mdiChartLine,
      mdiCube: mdiCube,
      mdiCart: mdiCart,
      mdiBarcodeScan: mdiBarcodeScan,
      mdiCash100: mdiCash100,
      mdiAccountMultipleOutline: mdiAccountMultipleOutline,
      mdiAccountLock: mdiAccountLock,
      mdiTruck: mdiTruck,
      mdiFactory: mdiFactory,
      mdiFormatListBulleted: mdiFormatListBulleted,
      mdiWarehouse: mdiWarehouse,
      mdiClockOutline: mdiClockOutline,
      mdiCompareHorizontal: mdiCompareHorizontal,
      mdiFileDocumentMultiple: mdiFileDocumentMultiple,
      mdiFilmstrip: mdiFilmstrip,
      mdiStrategy: mdiStrategy,
      mdiChartBubble: mdiChartBubble,
      mdiAlert: mdiAlert,
      mdiFingerprint: mdiFingerprint,
      mdiSwapHorizontal: mdiSwapHorizontal,
      mdiPuzzle: mdiPuzzle,
      mdiHome: mdiHome,
      mdiHelpCircle: mdiHelpCircle,
      mdiPercent: mdiPercent
    }
  },
  computed: {
    navDrawer: {
      get () {
        return this.$store.state.navDrawer
      },
      set (value) {
        this.$store.commit('navDrawer', value)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .navigation-drawer .showBadge {
    overflow: visible;
  }

  .navigation-drawer .showBadge .badge__badge{
    top: -5px;
    right: -25px;
  }

  .itemTree .list .list--group .list__tile {
      padding-left: 100px;
  }
  .itemTree .list .list__tile__action {
    min-width: 28px;
  }

  .logo_img {
    padding: 16px;
  }
  .logo_text {
    display: inline-block;
    padding-bottom: 16px;
    margin-left: 0;
  }
  /* avoid double line after expanded group */
  .list__group--active:after, .list__group--active:before {
    opacity: 0 !important;
  }
  /* dense but at least 14px */
  .list--dense .list__tile {
      font-size: 14px !important;
  }
  .v-list .v-list-item--active {
    color: #c0970e; /* primary */
  }
  .v-application.theme--dark .v-list .v-list-item--active {
    color: #c0970e; /* primary */
  }

  /* avoid disapering icon in group`header */
  .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    margin-left: 0px;
    min-width: 24px;
  }

</style>
