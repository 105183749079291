// import '@babel/polyfill'
import Vue from 'vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import App from './App.vue'
import ErrorMsg from './views/ErrorMsg.vue'
import './registerServiceWorker'
import EventBus from '@/components/EventBus'

Vue.config.productionTip = false

/*
  unused
Vue.filter('dateToDDMMYYYY', function (data, separator) {
  if (!data) {
    return
  }
  separator = separator || '/'
  const dateObj = new Date(data)
  // check if valid date
  if (!isNaN(Date.parse(dateObj))) {
    const d = dateObj.getDate()
    const m = dateObj.getMonth() + 1
    const y = dateObj.getFullYear()
    return '' + (d <= 9 ? '0' + d : d) + separator + (m <= 9 ? '0' + m : m) + separator + y
  }
})

Vue.filter('dateToYYYYMMDD', function (data, separator) {
  if (!data) {
    return
  }
  separator = separator || '/'
  const dateObj = new Date(data)
  // check if valid date
  if (!isNaN(Date.parse(dateObj))) {
    const d = dateObj.getDate()
    const m = dateObj.getMonth() + 1
    const y = dateObj.getFullYear()
    return '' + y + separator + (m <= 9 ? '0' + m : m) + separator + (d <= 9 ? '0' + d : d)
  }
})
*/

Vue.filter('toRounded', function (val, _digits) {
  if (typeof val !== 'undefined') {
    const digits = _digits || 0
    const x = Math.pow(10, digits)
    const rounded = Math.round((+val + 0.001 / x) * x) / x
    const num = rounded.toFixed(digits)
    if (localDecimalSeparator === ',') {
      return num.replace('.', ',')
    } else {
      return num
    }
  }
})

/**
 * format dateTime to local numeric format
 * eg: 20/11/2020 18:20 or 11-20-2020 6:20PM
*/
Vue.filter('toLocalDateTime', function (date) {
  return new Date(date).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })
})

/**
 * format date to local numeric format
 * eg: 20/11/2020 or 11-20-2020
*/
Vue.filter('toLocalDate', function (date) {
  return new Date(date).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })
})

// test for local decimal separator
// https://stackoverflow.com/questions/1074660/with-a-browser-how-do-i-know-which-decimal-separator-does-the-client-use
const localDecimalSeparator = (function whatDecimalSeparator () {
  let n = 1.1
  n = n.toLocaleString().substring(1, 2)
  return n
}())
store.commit('localDecimalSeparator', localDecimalSeparator)

// test for localStorage and Object.keys
// https://mathiasbynens.be/notes/localstorage-pattern
const hasLocalStorage = (function () {
  try {
    window.localStorage.setItem('test', 'test')
    window.localStorage.removeItem('test')
    console.log('Object.keys')
    console.log(!!Object.keys)
    // return true;
    return (!!Object.keys)
  } catch (exception) {
    return false
  }
}())
store.commit('hasLocalStorage', hasLocalStorage)

// test for mobile
// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
const isMobile = ('orientation' in window) ||
  navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/iPad/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Windows Phone/i) ||
  navigator.userAgent.match(/Mobile/i) || false
store.commit('isMobile', isMobile)

// get default settings from store
let dark = store.state.dark
let showItemInline = store.state.showItemInline // default to show inline
// get settings from local storage if available
if (hasLocalStorage) {
  let tmp = window.localStorage.getItem('dark')
  // avoid that unset localStorage overwrites default value
  if (typeof tmp !== 'undefined' && tmp !== null) {
    // local storage may save boolean as string
    dark = JSON.parse(window.localStorage.getItem('dark'))
    store.commit('dark', dark)
  }
  tmp = window.localStorage.getItem('showItemInline')
  // avoid that unset localStorage overwrites default value
  if (typeof tmp !== 'undefined' && tmp !== null) {
    // local storage may save boolean as string
    showItemInline = JSON.parse(window.localStorage.getItem('showItemInline'))
    store.commit('showItemInline', showItemInline)
  }
}

// get app uri
store.commit('clientHost', window.location.protocol + '//' + window.location.host)

// set default or dev apiHost
let apiHost = 'localhost:3004'
if (window.location.hostname === 'qadb2.quercus.cloud') {
  window.document.title = 'QADB2'
  apiHost = 'api.qadb2.quercus.cloud'
} else if (window.location.hostname === 'qedb2.quercus.cloud') {
  window.document.title = 'QEDB2'
  apiHost = 'api.qedb2.quercus.cloud'
}

store.commit('apiHost', window.location.protocol + '//' + apiHost)

// global handler for keydown events
document.addEventListener('keydown', (e) => {
  // console.log(e.keyCode)
  const simpleKeys = {
    13: 'keyEnter',
    27: 'keyEsc',
    40: 'keyDown',
    38: 'keyUp',
    34: 'keyNextPage',
    33: 'keyPrevPage',
    113: 'keyF2',
    117: 'keyF6'
  }

  // ctrl || mac ctrl
  const ctrlKeys = {
    45: 'ctrlKey_Ins',
    83: 'ctrlKey_s'
    // 79: 'ctrlKey_o',
    // 65: 'ctrlKey_a',
    // 13: 'ctrlKeyEnter',
    // 46: 'ctrlKeyDelete',
  }

  // ctrl || mac ctrl
  if ((e.ctrlKey || e.metaKey) && e.keyCode in ctrlKeys) {
    EventBus.$emit(ctrlKeys[e.keyCode], e)
  } else if (e.keyCode in simpleKeys) {
    EventBus.$emit(simpleKeys[e.keyCode], e)
  }
})

// dispatch init action, doing asynchronous stuff, commiting to store
store.dispatch('initApp')
  .then(() => {
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
    EventBus.$on('dispatchInitApp', () => {
      store.dispatch('initApp')
    })
  })
  // mount minium vue error page
  .catch((err) => {
    showErr('Error:' + err)
  })

// mount minium vue error page
function showErr (msg) {
  /* eslint-disable no-new */
  new Vue({
    vuetify,
    render: h => h(ErrorMsg, {
      props: {
        msg: msg
      }
    })
  }).$mount('#app')
}
