<template>
  <v-app
    id="app"
    :dark="dark"
    :style="dark ? 'color-scheme: dark;' : ''"
  >
    <nav>
      <Navigation
        v-if="['login', 'signup', 'pwdlost', 'authtoken'].indexOf($route.name) === -1"
      ></Navigation>
      <Toolbar
        v-if="['login', 'signup', 'pwdlost', 'authtoken'].indexOf($route.name) === -1"
      ></Toolbar>
    </nav>
    <v-main
      fill-height
    >
      <router-view>
      </router-view>
    </v-main>
    <SystemAlert></SystemAlert>
    <SwUpdate></SwUpdate>
  </v-app>
</template>

<script>
import Navigation from '@/components/Navigation'
import Toolbar from '@/components/Toolbar'
import SystemAlert from '@/components/SystemAlert'
import SwUpdate from '@/components/SwUpdate'

export default {
  name: 'App',
  components: {
    Navigation,
    Toolbar,
    SystemAlert,
    SwUpdate
  },
  computed: {
    dark () {
      return this.$store.state.dark || false
    }
  },
  watch: {
    dark: {
      handler: function (dark) {
        this.$vuetify.theme.dark = dark
      },
      // the callback will be called immediately after the start of the observation
      immediate: true
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  html {
    /* remove standard scrollbar from vuetify */
    overflow-y: hidden;
  }

  /* table with overflow on table level:;
      height: 100% !important; on all parent elements
      <v-container> without fill-height
      <v-row column class="overflowLayout">
      <v-col> for title that always should be displayed at native height and 100% width
      <v-col class="overflowFlex"> for element (table) that should overflow
  */
  html, body, .v-application, .v-application--wrap, .v-main, .v-main__wrap, .v-main__wrap .container {
    height: 100% !important;
    /* needed for IE11 */
    width: 100%;
  }
  .overflowLayout {
    max-height: 100%;
  }
  .overflowFlex {
    overflow: auto;
  }
  .overflowFlex .v-table__overflow {
    overflow: visible;
  }

  /* make toolbar of fullscreen dialog sticky and scroll element after toolbar */
  .dlgSticky {
    position: sticky !important;
  }
  .dlgScroll {
    overflow-y: auto !important;
    height: Calc(100vh - 64px) !important;
  }

  /* main menu with underline like in vuetify.com */
  .v-application.theme--light .v-bar--underline.theme--light {
    border-bottom-color: rgba(0,0,0,.12)!important;
  }

  /* modifications for dark theme */
  .v-application.theme--dark .v-bar--underline.theme--dark {
    border-bottom-color: rgba(255,255,255,.25)!important;
  }

  /* different box shadow for v-dialog-fullscreen with dark theme */
  .v-dialog--fullscreen .v-sheet.theme--dark.v-toolbar:not(.v-sheet--outlined) {
    box-shadow: 0px 2px 4px -1px rgba(255, 255, 255,.25), 0px 4px 5px 0px rgba(255, 255, 255, 0.14), 0px 1px 10px 0px rgba(255, 255, 255, 0.12);
    /* default for dark and light theme
      box-shadow: 0px 2px 4px -1px rgba(255, 255, 255, 0.2), 0px 4px 5px 0px rgba(255, 255, 255, 0.14), 0px 1px 10px 0px rgba(255, 255, 255, 0.12);
    */
  }

  /* create elevation box-shadow for dark theme */
  .v-application.theme--dark .elevation-1 {
    box-shadow: 0 2px 1px -1px rgba(255,255,255,.25),0 1px 1px 0 rgba(255,255,255,.14),0 1px 3px 0 rgba(255,255,255,.12)!important;
  }
  .v-application.theme--dark .elevation-2 {
    box-shadow: 0 3px 1px -2px rgba(255,255,255,.25),0 2px 2px 0 rgba(255,255,255,.14),0 1px 5px 0 rgba(255,255,255,.12)!important;
  }
  .v-application.theme--dark .elevation-3 {
    box-shadow: 0 3px 3px -2px rgba(255,255,255,.25),0 3px 4px 0 rgba(255,255,255,.14),0 1px 8px 0 rgba(255,255,255,.12)!important;
  }
  .v-application.theme--dark .elevation-4 {
    box-shadow: 0 2px 4px -1px rgba(255,255,255,.25),0 4px 5px 0 rgba(255,255,255,.14),0 1px 10px 0 rgba(255,255,255,.12)!important;
  }
  .v-application.theme--dark .elevation-5 {
    box-shadow: 0 3px 5px -1px rgba(255,255,255,.25),0 5px 8px 0 rgba(255,255,255,.14),0 1px 14px 0 rgba(255,255,255,.12)!important;
  }
  .v-application.theme--dark .elevation-6 {
    box-shadow: 0 3px 5px -1px rgba(255,255,255,.25),0 6px 10px 0 rgba(255,255,255,.14),0 1px 18px 0 rgba(255,255,255,.12)!important;
  }

  /* create v-dialog box-shadow for dark theme */
  .v-application.theme--dark .v-dialog {
    box-shadow: 0 11px 15px -7px rgba(255,255,255,.25), 0 24px 38px 3px rgba(255,255,255,.14), 0 9px 46px 8px rgba(255,255,255,.12);
  }

  .v-sheet.v-card.theme--dark:not(.v-sheet--outlined) {
    border: 1px solid rgb(255 255 255 / 25%);
    box-shadow: 0px 3px 1px -2px rgba(255,255,255,.25), 0px 2px 2px 0px rgba(255,255,255,.14), 0px 1px 5px 0px rgba(255,255,255,.12);
  }

  .v-sheet.v-card.v-card--flat.theme--dark {
    border: none;
  }

  /* create alert background color for dark theme */
  .v-application.theme--dark .v-alert--text:before {
    opacity: 0.3;
  }

  .v-application.theme--dark .v-menu__content {
    box-shadow: 0px 5px 5px -3px rgba(255,255,255,.25), 0px 8px 10px 1px rgba(255,255,255, 0.14), 0px 3px 14px 2px rgba(255,255,255, 0.12);
  }

  .v-application.theme--dark .v-divider {
    border-color: rgba(255, 255, 255,.25)!important;
  }

  /* dark theme tweaks for table */
  .v-application.theme--dark .v-data-table .v-data-footer {
    border-top-color: rgba(255,255,255,.25)!important;
  }

  .v-application.theme--dark .v-data-table >  .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom-color: rgba(255,255,255,.25)!important;
  }

  .v-application.theme--dark .v-data-table.v-data-table--fixed-header thead th {
    box-shadow: inset 0 -1px 0 rgba(255,255,255,.25);
  }

  .v-application.theme--dark .v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
    border-bottom-color: rgba(255,255,255,.25)!important;
  }
  .tableFilter {
    background-color: #f8f3e3 !important;
  }
  .v-application.theme--dark .tableFilter {
    background-color: #574d2c !important;
  }
  /* "filled" (..-enclosed) text field have bigger vertical distance than others that are not "filled" */
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: -10px;
  }

  /* more visible disabled inputs texts */
  .theme--light.v-input--is-disabled input,
  .theme--light.v-input--is-disabled textarea,
  .theme--light.v-select .v-select__selection--disabled {
    /* color: rgba(0, 0, 0, 0.38); */
    color: rgba(0, 0, 0, 0.75);
  }

  /*******************************/
  /* enhancements for dark theme */
  /*******************************/

  /* more visible disabled inputs texts */
  .theme--dark.v-input--is-disabled input,
  .theme--dark.v-input--is-disabled textarea,
  .theme--dark.v-select .v-select__selection--disabled {
    /* color: rgba(0, 0, 0, 0.38); */
    color: rgba(255, 255, 255, 0.75);
  }

  /* distinguishable background for input fields */
  .theme--dark.v-text-field--filled > .v-input__control > .v-input__slot {
      /* background: rgba(255, 255, 255, 0.25);*/
      /* direct color because of different parent backgrounds */
      background: rgba(255, 255, 255, 0.25);
  }

  .theme--dark.v-text-field--filled > .v-input__control > .v-input__slot {
      /* background: rgba(255, 255, 255, 0.25); */
  }

  .theme--dark.v-list-item:hover::before {
    opacity: 0.15;
  }

  .theme--dark.v-tabs .v-tab:hover::before {
    opacity: 0.40;
  }
  .theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::before {
      opacity: 0.3;
  }
  .v-application.theme--dark .tableFilter {
      background-color: #5f532a !important;
  }

  /* not so white */
  .theme--dark.v-application,
  .theme--dark.v-sheet,
  .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
  .theme--dark.v-data-table,
  .theme--dark.v-expansion-panels .v-expansion-panel,
  .theme--dark.v-input input, .theme--dark.v-input textarea,
  .theme--dark.v-card > .v-card__text, .theme--dark.v-card > .v-card__subtitle {
    color: rgba(255, 255, 255, 0.85);
  }

  .theme--dark.v-expansion-panels .v-expansion-panel,
  .theme--dark.v-text-field--solo > .v-input__control > .v-input__slot,
  .theme--dark.v-card.inlineItem,
  .theme--dark.v-data-table.inlineItem  {
    background-color: #575757;
  }

  .inlineItem .theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
    background: rgba(255, 255, 255, 0.25);
  }

  /* enable scroll on v-main__wrap */
  .mainScroll > .v-main__wrap {
    overflow: auto;
  }

  /* save space on inputs, hiding errors hide as long as no error occured */
  .hideInputMsg {
    /* vertical-align: top; ????*/
  }
  .hideInputMsg > div:not(.error--text) .v-text-field__details {
    display: none;
  }
  /* disable hover background color on tables */
  .trNoHighlight:hover {
    background: none !important;
  }

  /* reduced min table high !!??
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 48px;
  }
   */
  /* selected should be darker than hover */
  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
      /* background: #eeeeee; */
      background: #f5f5f5;
  }
  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr.v-data-table__selected:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
      background: #e1e1e1;
  }
  .theme--light.v-data-table tbody tr.v-data-table__selected {
      background: #eeeeee;
      /* background: #f5f5f5; */
  }

  /* table field nowrap */
  .nowrap {
    white-space: nowrap;
  }

  /* reduce horizontal padding for dense table */
  .v-data-table.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td,
  .v-data-table.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > th {
      padding: 0 4px;
  }

</style>
