// src/api.js

import axios from 'axios'
import store from './store'

const DEFAULT_ERR_MSG = 'Se ha producido un error.'
const AXIOS_CONFIG = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
}

const AXIOS_CONFIG_FILEUPLOAD = {
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data'
    // 'Content-Type': 'application/json;charset=UTF-8'
  }
}

export default {

  // **********************************************************
  // session
  // **********************************************************

  // login => create session
  // returns user, 2fa request object or err
  login (data) {
    const API_URL = store.state.apiHost + '/api/session'
    return axios.post(API_URL, data, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  // logout => delete session
  // returns nothing or err
  logout () {
    const API_URL = store.state.apiHost + '/api/session'
    return axios.delete(API_URL, AXIOS_CONFIG)
      .catch(errorHandler)
  },

  // **********************************************************
  // 2fa
  // **********************************************************

  /**
   * prepare 2fa
   * @returns object with secret and qrcode
   */
  prepareTwoFactor () {
    const API_URL = store.state.apiHost + '/api/2fa'
    return axios.get(API_URL, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  /**
   * disable 2fa
   * success / error is shown through http response codes
   * @param data (string) 2fa token
   * @returns undefined
   */
  disableTwoFactor (token) {
    const data = {
      token: token
    }
    const API_URL = store.state.apiHost + '/api/2fa'
    return axios.patch(API_URL, data, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  /**
   * enable 2fa
   * success / error is shown through http response codes
   * @param data (object) password and 2fa token
   * @returns undefined
   */
  enableTwoFactor (data) {
    const API_URL = store.state.apiHost + '/api/2fa'
    return axios.post(API_URL, data, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  // **********************************************************
  // authtoken
  // **********************************************************

  // checkAuthtoken
  // returns authObj or err
  checkAuthtoken (authtoken) {
    const API_URL = store.state.apiHost + '/api/authtoken/' + authtoken
    return axios.get(API_URL, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  // create a Authtoken action
  // the type of action is defined by the passed 'action'
  // signup => create user + send auth link to email
  // resetPassword => send auth link to email
  // returns nothing or err
  createAuthtokenAction (data) {
    const API_URL = store.state.apiHost + '/api/authtoken'
    return axios.post(API_URL, data, AXIOS_CONFIG)
      .catch(errorHandler)
  },

  // execute authtokenAction
  // returns nothing or err
  doAuthtokenAction (data) {
    const API_URL = store.state.apiHost + '/api/authtoken'
    return axios.put(API_URL, data, AXIOS_CONFIG)
      .catch(errorHandler)
  },

  // ********************************************************
  // generic CRUD functions
  // ********************************************************

  getItems (path, _options) {
    const API_URL = store.state.apiHost + '/api/' + path
    const options = { ..._options }
    delete options.multiSort
    delete options.mustSort
    const config = { ...AXIOS_CONFIG, ...{ params: options } }
    console.log(config)
    return axios.get(API_URL, config)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  getItem (path, id) {
    const API_URL = store.state.apiHost + '/api/' + path + '/' + id
    return axios.get(API_URL, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  createItem (path, data) {
    const API_URL = store.state.apiHost + '/api/' + path
    return axios.post(API_URL, data, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  updateItem (path, id, data) {
    const API_URL = store.state.apiHost + '/api/' + path + '/' + id
    return axios.put(API_URL, data, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  removeItem (path, id) {
    const API_URL = store.state.apiHost + '/api/' + path + '/' + id
    return axios.delete(API_URL, AXIOS_CONFIG)
      .catch(errorHandler)
  },

  /**
   * get select for client persons
   * @param clientId (integer)
   * @returns Array of Persons objects
   */
  getClientPersonsSelect (clientId) {
    const API_URL = store.state.apiHost + '/api/clientpersons/' + clientId
    return axios.get(API_URL, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  /**
   * get select for client addresses
   * @param clientId (integer)
   * @returns Array of Addresses objects
   */
  getClientAddressesSelect (clientId) {
    const API_URL = store.state.apiHost + '/api/clientaddresses/' + clientId
    return axios.get(API_URL, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  /**
   * get last shipNo and shipDate ignoring given saleId
   * @param saleId (integer)
   * @returns Object {lastShipNo, lastShipDate}
   */
  getLastShipNo (saleId) {
    const API_URL = store.state.apiHost + '/api/lastshipno/' + saleId
    return axios.get(API_URL, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  /**
   * get last invoiceNo and invoiceDate forignoring given saleId
   * @param saleId Integer
   * @param invoiceSerie String (optional)
   * @returns Object {lastInvoiceNo, lastInvoiceDate}
   */
  getLastInvoiceNo (saleId, invoiceSerie) {
    const API_URL = store.state.apiHost + '/api/lastinvoiceno/' + saleId
    const config = invoiceSerie ? { ...AXIOS_CONFIG, ...{ params: { invoiceSerie } } } : AXIOS_CONFIG
    return axios.get(API_URL, config)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  /**
   * get next purchase receptNo
   * @returns {receptNo: x}
   */
  getPurchasesNextReceptNo () {
    const API_URL = store.state.apiHost + '/api/purchasesnextreceptno'
    return axios.get(API_URL, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  /**
   * get available palletIds for all products of sale
   * @param saleId (integer)
   * @returns Array of Addresses objects
   */
  getSaleAvailablePallets (saleId) {
    const API_URL = store.state.apiHost + '/api/availablepallets/' + saleId
    return axios.get(API_URL, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  /**
   * get available palletIds for all products of sale
   * @param saleId (integer)
   * @returns Array of Addresses objects
   */
  getProductsUsingPriceGroup (priceGroupId) {
    const API_URL = store.state.apiHost + '/api/productsusingpricegroup/' + priceGroupId
    return axios.get(API_URL, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  /**
   * get product availability
   * @param saleId (integer)
   * @returns Array of Addresses objects
   */
  getProductAvailability (productId) {
    const API_URL = store.state.apiHost + '/api/productavailability/' + productId
    return axios.get(API_URL, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  /**
   * get available reports
   * @param data (object)
   * @returns response undefined
   */
  queryReport (reportName, from, till) {
    const API_URL = store.state.apiHost + '/api/reports/' + reportName
    const config = { ...AXIOS_CONFIG, ...{ params: { from: from, till: till } } }
    return axios.get(API_URL, config)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  /**
   * get existing palletIds for any productId
   * @param productId (string)
   * @returns palletIds (array)
   */
  getTraceabilityPalletIds (productId) {
    const API_URL = store.state.apiHost + '/api/traceabilitypalletids/' + productId
    return axios.get(API_URL, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },
  /**
   * get existing clientIds for any productId / palletId
   * @param productId (string)
   * @param palletId (string)
   * @returns clientIds (array)
   */
  getTraceabilityClientIds (productId, palletId) {
    const API_URL = store.state.apiHost + '/api/traceabilityclientids/' + productId + '/' + palletId
    return axios.get(API_URL, AXIOS_CONFIG)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  /**
   * generate reminders
   * success / error is shown through http response codes
   * @param data (object)
   * @returns response undefined
   */
  generateReminders (data) {
    const API_URL = store.state.apiHost + '/api/reminders'
    return axios.post(API_URL, data, AXIOS_CONFIG)
      .catch(errorHandler)
  },

  /**
   * request rate for shipment
   * success / error is shown through http response codes
   * @param data (object)
   * @returns response undefined
   */
  getShipmentRate (data) {
    const API_URL = store.state.apiHost + '/api/shipment/rate'
    const config = { ...AXIOS_CONFIG, ...{ params: data } }
    return axios.get(API_URL, config)
      .then(response => {
        return response.data
      })
      .catch(errorHandler)
  },

  /**
   * request shipment
   * success / error is shown through http response codes
   * @param data (object)
   * @returns response undefined
   */
  requestShipment (data) {
    const API_URL = store.state.apiHost + '/api/shipment'
    return axios.post(API_URL, data, AXIOS_CONFIG)
      .catch(errorHandler)
  },

  /**
   * upload file
   * success / error is shown through http response codes
   * @param data (object)
   * @returns response undefined
   */
  uploadFile (data, uploadPath) {
    const API_URL = store.state.apiHost + uploadPath
    return axios.post(API_URL, data, AXIOS_CONFIG_FILEUPLOAD)
      .catch(errorHandler)
  }

}

// throw server error message if available
function errorHandler (e) {
  if (e.response && e.response.data && e.response.data.errText) {
    throw new Error(e.response.data.errText)
  } else {
    throw new Error(DEFAULT_ERR_MSG)
  }
}
