<template>
  <v-dialog
    :value="show"
    persistent
    max-width="400px"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title>
        <h3 class="headline mb-0">
          Actualización disponible
        </h3>
      </v-card-title>
      <v-card-text>
        Hay disponible una versión actualizada de esta applicación. Por favor actualiza.
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="cancel"
        >
          Ahora no
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="refreshApp"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data () {
    return {
      show: false,
      refreshing: false,
      registration: null
    }
  },
  created () {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (!this.refreshing) {
        this.refreshing = true
        // Here the actual reload of the page occurs
        window.location.reload()
      }
    })
  },

  methods: {
    // Store the SW registration so we can send it a message
    updateAvailable (event) {
      this.registration = event.detail
      this.show = true
    },

    // Called when the user accepts the update
    refreshApp () {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (this.registration && this.registration.waiting) {
        // send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
      this.show = false
    },
    cancel () {
      this.show = false
    }
  }
}

</script>
