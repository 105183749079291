<template>
  <nav>
    <v-app-bar
      app
      outlined
      flat
      clipped-left
      color="primary"
    >
      <v-toolbar-title
        class="toolbarTitle"
      >
      <v-btn
        icon
        @click.stop="toogleNavDrawer"
        aria-label="Toggle menu"
      >
        <v-img
          src="/img/icons/icon_black.svg"
          alt="QDB2 Logo"
          max-width="32"
          max-height="32"
        >
        </v-img>
      </v-btn>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-autocomplete
        ref="clientSearch"
        style="max-width: 350px"
        dense
        auto-select-first
        :background-color="dark ? '#5f532a' : '#f8f3e2'"
        class="mx-4"
        flat
        hide-no-data
        hide-details
        solo
        clearable

        v-model="selectedClientId"
        :search-input.sync="searchInput"
        :items="clientsSelect"
        item-text="search"
        item-value="clientId"
        label="Buscar cliente (F6)"
        @keydown.esc="resetSearch"
      >
      </v-autocomplete>
      <div
        class="d-flex align-center"
      >
        <v-menu
          bottom
          left
          offset-y
        >
          <template v-slot:activator="{ on }">
            <!-- dark icon > light -->
            <v-btn
              light
              class="userIcon"
              icon
              v-on="on"
              aria-label="User Account"
            >
              <v-icon
              >
                {{ mdiAccountCircle }}
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon
                    large
                  >{{ mdiAccountCircle }}
                </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{user.userName}}</v-list-item-title>
                  <v-list-item-subtitle>{{user.userEmail}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list>
              <v-list-item
                @click="logout"
              >
                <v-list-item-title>Cerrar sesión</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="reload"
              >
                <v-list-item-title>Recargar página</v-list-item-title>
              </v-list-item>
              <v-list-item
                router-link to="/usersettings"
              >
                <v-list-item-title>Opciones de usuario</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </v-app-bar>
  </nav>
</template>

<script>
import api from '../api'
import { mdiAccountCircle } from '@mdi/js'
import EventBus from '@/components/EventBus'
import Vue from 'vue'

export default {
  data () {
    return {
      mdiAccountCircle: mdiAccountCircle,
      drawer: null,
      selectedClientId: null,
      searchInput: ''
    }
  },
  mounted () {
    // listen to F6 (win) and xx (mac)
    // document.addEventListener('keydown', this.focusSearch)
    EventBus.$on('keyF6', this.focusSearch)
  },
  beforeDestroy () {
    // document.removeEventListener('keydown', this.focusSearch)
    EventBus.$off('keyF6', this.focusSearch)
  },
  computed: {
    dark () {
      return this.$store.state.dark
    },
    user () {
      return this.$store.state.user
    },
    clientsSelect () {
      return this.$store.state.clientsSelect
    }
  },
  watch: {
    selectedClientId: {
      handler (id) {
        console.log('id', id)
        console.log('selectedClientId', this.selectedClientId)
        if (
          id && this.$route.params &&
          (this.$route.params.id !== id || this.$route.name !== 'client')
        ) {
          console.log(this.$route)
          this.$router.push({
            name: 'client',
            params: {
              id: id,
              submenu: 'details'
            }
          })
          // reset search field
          Vue.nextTick(() => {
            this.selectedClientId = null
            this.searchInput = ''
          })
        }
      }
    }
  },
  methods: {
    resetSearch: function () {
      // reset search field
      Vue.nextTick(() => {
        this.selectedClientId = null
        this.searchInput = ''
      })
    },
    focusSearch: function (e) {
      if (e.keyCode === 117) {
        console.log('keycode 117 (F6)')
        e.preventDefault()
        this.$refs.clientSearch.focus()
      }
    },
    keyShortcut (key) {
      if (key === 'keyF6') {
        console.log('keycode 117 (F6)')
        // e.preventDefault()
        this.$refs.clientSearch.focus()
      }
    },

    toogleNavDrawer: function () {
      this.$store.commit('navDrawer', !this.$store.state.navDrawer)
    },
    logout: function () {
      console.log('logout')
      api.logout()
        .then(() => {
          this.$store.commit('user', false)
          this.$router.push('/login')
        })
    },
    reload: function () {
      window.location.reload(true)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .toolbarColor {
    background-color: #c0970e;
  }
</style>
