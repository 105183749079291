import Vue from 'vue'
import Vuex from 'vuex'
import errorMessages from './errorMessages'
import initialStates from './initialStates'
import api from '../api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appVersion: process.env.PACKAGE_VERSION || '0',
    apiVersion: '0',
    dbVersion: '0',

    deliveryDocFile: '',
    deliveryDocDisplay: '',
    deliveryDocCreation: false,
    invoiceDocCreation: false,
    invoiceDocSepa: false,
    invoiceDocCnText: false,

    hasLocalStorage: false,
    localDecimalSeparator: ',',
    isMobile: false,
    clientHost: null,
    apiHost: null,
    loading: true,
    navDrawer: null,
    dark: false, // dark theme
    showItemInline: true,

    user: false, // false or {}
    availablePermissions: {},
    client: false,
    productsSelect: [],
    suppliersSelect: [],
    clientsSelect: [],
    forwardersSelect: [],
    usersSelect: [],
    isocountrySelect: [],
    invoiceSeriesSelect: [],
    docFormatSelect: [],
    paymentTerms: [],
    priceGroups: [],
    priceGroupLevels: [],
    priceListSelect: [],
    currenciesSelect: [],
    originSelect: [],
    familySelect: [],
    toastSelect: [],
    typeSelect: [],
    regionSelect: [],
    actiontypeSelect: [],
    marketingSelect: [],
    giftSelect: [],
    defaultsSelect: [],
    defaultCurrency: false,
    defaultCurrencySymbol: false,
    alert: initialStates.alert(),
    errorMessages: errorMessages

  },
  getters: {
    getDefault: (state) => (key) => {
      return (state.defaultsSelect.find(obj => obj.key === key) || {}).value
    }
  },
  mutations: {
    hasLocalStorage (state, v) {
      state.hasLocalStorage = (v)
    },
    localDecimalSeparator (state, v) {
      state.localDecimalSeparator = (v)
    },
    isMobile (state, v) {
      state.isMobile = (v)
    },

    deliveryDocFile (state, v) {
      state.deliveryDocFile = v
    },
    deliveryDocDisplay (state, v) {
      state.deliveryDocDisplay = v
    },
    deliveryDocCreation (state, v) {
      state.deliveryDocCreation = (v)
    },
    invoiceDocCreation (state, v) {
      state.invoiceDocCreation = (v)
    },
    invoiceDocSepa (state, v) {
      state.invoiceDocSepa = (v)
    },
    invoiceDocCnText (state, v) {
      state.invoiceDocCnText = (v)
    },

    clientHost (state, v) {
      state.clientHost = v
    },
    apiHost (state, v) {
      state.apiHost = v
    },
    apiVersion (state, v) {
      state.apiVersion = v
    },
    dbVersion (state, v) {
      state.dbVersion = v
    },
    loading (state, v) {
      state.loading = (v)
    },
    navDrawer (state, v) {
      state.navDrawer = (v)
    },
    dark (state, v) {
      state.dark = (v)
    },
    showItemInline (state, v) {
      state.showItemInline = (v)
    },
    twoFactor (state, v) {
      state.user.twoFactor = (v)
    },

    user (state, user) {
      state.user = user
    },

    availablePermissions (state, v) {
      state.availablePermissions = v
    },

    client (state, v) {
      state.client = v
    },

    supplier (state, v) {
      state.supplier = v
    },

    defaultCurrency (state, v) {
      state.defaultCurrency = v
    },
    defaultCurrencySymbol (state, v) {
      state.defaultCurrencySymbol = v
    },
    currenciesSelect (state, v) {
      state.currenciesSelect = v
    },
    originSelect (state, v) {
      state.originSelect = v
    },
    familySelect (state, v) {
      state.familySelect = v
    },
    toastSelect (state, v) {
      state.toastSelect = v
    },
    typeSelect (state, v) {
      state.typeSelect = v
    },
    regionSelect (state, v) {
      state.regionSelect = v
    },
    actiontypeSelect (state, v) {
      state.actiontypeSelect = v
    },
    marketingSelect (state, v) {
      state.marketingSelect = v
    },
    giftSelect (state, v) {
      state.giftSelect = v
    },
    defaultsSelect (state, v) {
      state.defaultsSelect = v
    },

    invoiceSeriesSelect (state, v) {
      state.invoiceSeriesSelect = v
    },

    isocountrySelect (state, v) {
      state.isocountrySelect = v
    },
    docFormatSelect (state, v) {
      state.docFormatSelect = v
    },
    paymentTerms (state, v) {
      state.paymentTerms = v
    },
    priceGroupLevels (state, v) {
      state.priceGroupLevels = v
    },
    priceGroups (state, v) {
      state.priceGroups = v
    },
    priceListSelect (state, v) {
      state.priceListSelect = v
    },
    productsSelect (state, v) {
      state.productsSelect = v
    },
    suppliersSelect (state, v) {
      state.suppliersSelect = v
    },
    clientsSelect (state, v) {
      state.clientsSelect = v
    },
    forwardersSelect (state, v) {
      state.forwardersSelect = v
    },
    usersSelect (state, v) {
      state.usersSelect = v
    },
    alert (state, dlg) {
      // reset to initial state before showing
      if (!state.alert.show && dlg.show) {
        state.alert = initialStates.alert()
      }
      // Object.assign(state.alert, dlg) doesn't trigger change sometimes
      state.alert = Object.assign({}, state.alert, dlg)
    }

  },
  actions: {
    initApp (context) {
      console.log('initApp')
      return api.getItems('init')
        .then(data => {
          context.commit('user', data.user)
          context.commit('apiVersion', data.apiVersion)
          context.commit('dbVersion', data.dbVersion)

          context.commit('deliveryDocFile', data.deliveryDocFile)
          context.commit('deliveryDocDisplay', data.deliveryDocDisplay)
          context.commit('deliveryDocCreation', data.deliveryDocCreation)
          context.commit('invoiceDocCreation', data.invoiceDocCreation)
          context.commit('invoiceDocSepa', data.invoiceDocSepa)
          context.commit('invoiceDocCnText', data.invoiceDocCnText)

          context.commit('availablePermissions', data.availablePermissions)
          context.commit('priceGroups', data.priceGroups)
          context.commit('priceGroupLevels', data.priceGroupLevels)
          context.commit('defaultCurrency', data.defaultCurrency)
          context.commit('defaultCurrencySymbol', data.defaultCurrencySymbol)
          context.commit('isocountrySelect', data.isocountrySelect)
          context.commit('invoiceSeriesSelect', data.invoiceSeriesSelect)
          context.commit('docFormatSelect', data.docFormatSelect)
          context.commit('priceListSelect', data.priceListSelect)
          context.commit('paymentTerms', data.paymentTerms)
          context.commit('currenciesSelect', data.currenciesSelect)
          context.commit('originSelect', data.originSelect)
          context.commit('familySelect', data.familySelect)
          context.commit('toastSelect', data.toastSelect)
          context.commit('regionSelect', data.regionSelect)
          context.commit('actiontypeSelect', data.actiontypeSelect)
          context.commit('typeSelect', data.typeSelect)
          context.commit('marketingSelect', data.marketingSelect)
          context.commit('giftSelect', data.giftSelect)
          context.commit('defaultsSelect', data.defaultsSelect)
          context.commit('suppliersSelect', data.suppliersSelect)
          context.commit('productsSelect', data.productsSelect)
          context.commit('clientsSelect', data.clientsSelect)
          context.commit('forwardersSelect', data.forwardersSelect)
          context.commit('usersSelect', data.usersSelect)
          console.log('initial data received')
        })
    }
  }
})
