// initialStates.js

export default {
  alert () {
    return {
      show: false,
      text: '',
      type: 'info',
      timeout: 5000
    }
  }
}
